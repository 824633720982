import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Backlog from "./Backlog";
import KanbanBoard from "../KanbanBoard/KanbanBoard";
import { onDragEndPurchase } from "../KanbanBoard/PurchaseKanban/onDragEndPurchase";
import { generateColumnsFromPurchases } from "../KanbanBoard/PurchaseKanban/ColumnsPurchasesKanban";
import PurchaseKanban from "../KanbanBoard/PurchaseKanban/PurchaseKanban";
import { PurchaseContext } from "./context/PurchaseContext";
import { Modal } from "@mui/material";
import ModalDetail from "../../Purchase/ModalDetail";
import { Car } from "../../../entities/car.entity";
import { WorkshopPurchase } from "../../../entities/workshop-purchase.entity";
import { WorkshopPurchaseState } from "../../../entities/workshop-purchase-state.entity";
import { useContext, useEffect, useMemo, useState } from "react";
import { WorkshopService } from "../../../services/WorkshopService";
import { User } from "../../../entities/user.entity";
import { ColumnsPurchase } from "../KanbanBoard/PurchaseKanban/TypeKanbanPurchase";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Index() {
  const {
    selectedPurchase,
    setSelectedPurchase,
    open,
    setOpen,
    loading,
    kanbanPurchases,
    updatePurchase,
  } = useContext(PurchaseContext);

  const [value, setValue] = useState(0);
  const [responsibleList, setResponsibleList] = useState<User[]>([]);
  const [columns, setColumns] = useState<ColumnsPurchase>({});
  const workshopService = new WorkshopService();

  const handleSetItems = async (updatedItems: WorkshopPurchase[]) => {
    if (!Array.isArray(updatedItems)) {
      console.error(
        "handleSetItems recibió un valor que no es un array:",
        updatedItems
      );
      return;
    }

    for (const purchase of updatedItems) {
      const stateId = purchase.workshopPurchaseState?.id;

      if (stateId) {
        await updatePurchase(purchase.id, {
          workshop_purchase_state_id: stateId,
        });
      } else {
        console.error(
          `No se encontró un estado válido para la compra con id: ${purchase.id}`
        );
      }
    }
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const filteredPurchases = useMemo(() => {
    const currentTime = new Date();
    return kanbanPurchases.filter((item: any) => {
      let relevantDate: Date | undefined;
      let thresholdDays: number;

      if (item.workshopPurchaseState.name === "Finalizado") {
        relevantDate = item.end_date ? new Date(item.end_date) : undefined;
        thresholdDays = 7;
      } else if (item.workshopPurchaseState.name === "Rechazada") {
        relevantDate = item.rejected_date
          ? new Date(item.rejected_date)
          : undefined;
        thresholdDays = 7;
      } else if (item.workshopPurchaseState.name === "Vendida") {
        relevantDate = item.expiration_date
          ? new Date(item.expiration_date)
          : undefined;
        thresholdDays = 7;
      } else {
        return true;
      }

      if (relevantDate) {
        const timeDiff = currentTime.getTime() - relevantDate.getTime();
        const minutesDiff = timeDiff / (1000 * 60 * 60 * 60 * 24);
        return minutesDiff < thresholdDays;
      }
      return false;
    });
  }, [kanbanPurchases]);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await workshopService.users(
        filteredPurchases[0]?.workshop.id
      );
      setResponsibleList(users);
    };

    if (filteredPurchases.length > 0) {
      fetchUsers();
    }
  }, [filteredPurchases]);

  useEffect(() => {
    if (responsibleList.length > 0) {
      setColumns(
        generateColumnsFromPurchases(filteredPurchases, responsibleList)
      );
    }
  }, [filteredPurchases, responsibleList]);

  const handleClose = () => setOpen(false);

  const getColumnBackgroundColor = (columnId: number) => {
    switch (columnId) {
      case WorkshopPurchaseState.QUOTE_ID:
      case WorkshopPurchaseState.BUY_ID:
      case WorkshopPurchaseState.QUOTED_ID:
      case WorkshopPurchaseState.FINISHED_ID:
      case WorkshopPurchaseState.DELIVERED_ID:
      case WorkshopPurchaseState.SPEC_ID:
      case WorkshopPurchaseState.CANCELLED_ID:
      case WorkshopPurchaseState.EXPIRED_ID:
      case WorkshopPurchaseState.REJECTED_ID:
        return "rgba(170, 170, 170, 0.1)";
      default:
        return "white";
    }
  };

  const renderPurchaseModal = () => {
    return (
      selectedPurchase && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <ModalDetail
              carSummary={Car.getSummary(selectedPurchase.budget.car)}
              purchase={selectedPurchase}
              handleClose={handleClose}
              onPurchaseChange={async (updatedPurchase: WorkshopPurchase) => {
                await updatePurchase(updatedPurchase.id, updatedPurchase);
                setSelectedPurchase(updatedPurchase);
              }}
            />
          </Box>
        </Modal>
      )
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Board" {...a11yProps(0)} />
          <Tab label="Backlog" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <KanbanBoard<WorkshopPurchase>
          onDragEndHandler={onDragEndPurchase}
          renderItems={(provided: any, task: any, handleOpen: any) => (
            <PurchaseKanban
              provided={provided}
              task={task}
              handleOpen={handleOpen}
            />
          )}
          setSelectedItem={setSelectedPurchase}
          open={open}
          setOpen={setOpen}
          items={kanbanPurchases}
          setItems={handleSetItems}
          renderModal={renderPurchaseModal}
          loading={loading}
          getColumnBackgroundColor={getColumnBackgroundColor}
          columns={columns}
          setColumns={setColumns}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Backlog />
      </CustomTabPanel>
    </Box>
  );
}
