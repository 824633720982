import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Tooltip,
  Input,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import {
  ModeEdit as ModeEditIcon,
  AddBox as AddBoxIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { RepairSubitem } from "../../entities/repair-subitem.entity";
import { WorkshopPurchaseItem } from "../../entities/workshop-purchase-item.entity";
import { PurchaseContext } from "../Pages/Purchase/context/PurchaseContext";
import { WorkshopPurchaseService } from "../../services/WorkshopPurchaseService";
import { WorkshopPurchaseItemService } from "../../services/WorkshopPurchaseItemService";
import { useQueryClient } from "@tanstack/react-query";

interface PurchaseItemRowProps {
  item: WorkshopPurchaseItem;
  isEditionDisabled: boolean | undefined;
  isBudgetView: boolean;
}

export default function PurchaseBrandRow({
  item,
  isEditionDisabled,
  isBudgetView,
}: PurchaseItemRowProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isPartNumberVisible, setIsPartNumberVisible] = useState(false);
  const [partNumber, setPartNumber] = useState<string>(
    item.part?.part_number || ""
  );
  const [brands, setBrands] = useState<{ id: number; name: string }[]>([]);
  const [selectedBrandId, setSelectedBrandId] = useState<number | undefined>(
    item.part?.part_brand_id
  );
  const [selectedBrandName, setSelectedBrandName] = useState<
    string | undefined
  >();

  const { selectedPurchase } = useContext(PurchaseContext);
  const workshopPurchaseService = new WorkshopPurchaseService();
  const workshopPurchaseItemService = new WorkshopPurchaseItemService();
  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const brandsData = await workshopPurchaseService.getPartBrands();
        setBrands(brandsData);

        if (item.part) {
          const brand = brandsData.find(
            (brand: any) => brand.id === item.part?.part_brand_id
          );
          if (brand) {
            setSelectedBrandId(brand.id);
            setSelectedBrandName(brand.name);
          }
        }
      } catch (error) {
        enqueueSnackbar("Error al cargar las marcas", { variant: "error" });
      }
    };

    fetchBrands();
  }, [item]);

  const handleIconClick = () => {
    setIsPartNumberVisible(!isPartNumberVisible);
  };

  const fetchExistingPartNumber = async (brandId: number) => {
    const car = selectedPurchase?.budget?.car;
    try {
      const response = await workshopPurchaseService.getPart({
        part_brand_id: { eq: brandId },
        brand_id: { eq: car?.brand_id },
        car_model_id: { in: [car?.carModel?.id || null] },
        car_version_id: { in: [car?.carVersion?.id || null] },
        repair_subitem_id: { eq: item.budgetItem.repairSubitem.id },
      });
      if (response.success && response.data.length > 0) {
        return response.data[0].part_number || "";
      }
      return "";
    } catch (error) {
      enqueueSnackbar("Error al obtener el número de pieza existente", {
        variant: "error",
      });
      return "";
    }
  };

  const updatePartData = async (partData: any, updateType: string) => {
    if (!selectedPurchase) {
      enqueueSnackbar("Error: No se encontró la compra seleccionada.", {
        variant: "error",
      });
      return;
    }

    try {
      const updatedPart = await workshopPurchaseService.updatePart(partData);
      const partId = updatedPart.id;

      await workshopPurchaseItemService.update(item.id, {
        part_id: partId,
      });

      queryClient.invalidateQueries({ queryKey: ["purchases"] });
      queryClient.invalidateQueries({ queryKey: ["kanbanPurchases"] });

      const message =
        updateType === "partNumber"
          ? "Número de pieza actualizado con éxito"
          : "Marca actualizada con éxito";

      enqueueSnackbar(message, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Error al actualizar el número de pieza", {
        variant: "error",
      });
    }
  };

  const handlePartNumberBlur = async (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setPartNumber(value);
    setIsPartNumberVisible(false);

    const car = selectedPurchase?.budget?.car;

    const partData = {
      part_brand_id: selectedBrandId || null,
      brand_id: car?.brand_id,
      car_model_id: car?.carModel?.id || null,
      car_version_id: car?.carVersion?.id || null,
      repair_subitem_id: item.budgetItem.repairSubitem.id,
      part_number: value,
    };
    await updatePartData(partData, "partNumber");
  };

  const handleBrandChange = async (event: SelectChangeEvent<number>) => {
    const selectedId = Number(event.target.value);
    setSelectedBrandId(selectedId);

    const existingPartNumber = await fetchExistingPartNumber(selectedId);

    if (existingPartNumber) {
      setPartNumber(existingPartNumber);
    } else {
      setPartNumber("");
    }

    const car = selectedPurchase?.budget?.car;

    const partData = {
      part_brand_id: selectedId,
      brand_id: car?.brand_id,
      car_model_id: car?.carModel?.id || null,
      car_version_id: car?.carVersion?.id || null,
      repair_subitem_id: item.budgetItem.repairSubitem.id,
      part_number: existingPartNumber || null,
    };
    await updatePartData(partData, "brand");
  };

  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text).then(
      () => {
        enqueueSnackbar(`N° de pieza copiado: ${text} `, {
          variant: "success",
        });
      },
      (err) => {
        enqueueSnackbar(`Error al copiar el N° de pieza`, {
          variant: "error",
        });
        console.error("Error al copiar el N° de pieza: ", err);
      }
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      {isPartNumberVisible ? (
        <Input
          fullWidth
          autoFocus
          id={`part-number-${item.id}`}
          defaultValue={partNumber || ""}
          onBlur={handlePartNumberBlur}
          sx={{ fontSize: ".8rem", fontWeight: "450" }}
        />
      ) : (
        <Tooltip
          title={
            <span>
              Marca: {selectedBrandName || "No definido"}
              <br />
              N° Pieza: {partNumber || "No definido"}
            </span>
          }
          arrow
          disableHoverListener={!selectedBrandId}
          placement="top"
        >
          <FormControl fullWidth size="small">
            <InputLabel
              id={`select-brand-label-${item.id}`}
              shrink={!!selectedBrandId || !!partNumber}
            >
              {selectedBrandId && partNumber && `Nº ${partNumber}`}
            </InputLabel>

            <Select
              labelId={`select-brand-label-${item.id}`}
              id={`item-brand-${item.id}`}
              value={selectedBrandId || ""}
              displayEmpty
              disabled={
                isEditionDisabled ||
                item.budgetItem.repairSubitem.repair_subitem_type_id !==
                  RepairSubitem.REPLACEMENT_TYPE_ID ||
                isBudgetView
              }
              onChange={handleBrandChange}
              label={selectedBrandId && partNumber && `Nº ${partNumber}`}
            >
              <MenuItem value="" disabled>
                <em>Marca</em>
              </MenuItem>
              {brands.map((brand) => (
                <MenuItem key={brand.id} value={brand.id}>
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>
      )}
      {!isBudgetView && selectedBrandId && (
        <Box sx={{ display: "flex" }}>
          <Tooltip
            title={partNumber ? "Editar N° de pieza" : "N° de pieza"}
            arrow
            placement="top"
          >
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={handleIconClick}
            >
              {partNumber ? (
                <ModeEditIcon fontSize="small" />
              ) : (
                <AddBoxIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          {partNumber && (
            <Tooltip title="Copiar N° de pieza" arrow placement="top">
              <IconButton
                color="primary"
                onClick={() => copyToClipboard(partNumber)}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
}
