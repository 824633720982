import ApiService from "./ApiService";

export class UserService {
  async getMechanics(): Promise<number[]> {
    try {
      const response = await ApiService.get("/api_v2/user/mechanics");
      return response.data.data;
    } catch (error) {
      console.error("Error fetching mechanics:", error);
      throw error;
    }
  }
}
