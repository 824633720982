import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  Tooltip,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { styled } from "@mui/system";
import BarcodeScanner from "./BarcodeScanner";
import { useDarkMode } from "../../ThemeContext";
import "../../../index.css";

const categories = ["Category 1", "Category 2", "Category 3"];

const ProductForm: React.FC = () => {
  const [formValues, setFormValues] = useState({
    itemName: "",
    code: "",
    openingStock: 1,
    reorderPoint: "",
    category: "",
    costPrice: "",
  });
  const [imageUrls, setImageUrls] = useState<string | null>(null);
  const [scanedCode, setScanedCode] = useState<string>("");
  const [scannerOpen, setScannerOpen] = useState<boolean>(false);
  const { isDarkMode } = useDarkMode();
  const handleScan = (barcode: string) => {
    setScanedCode(barcode);
    setScannerOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // agregar la lógica para enviar el formulario
  };

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newImageUrl = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(files[0]);
      });
      setImageUrls(newImageUrl);
    }
  };

  const handleDeleteImage = () => {
    setImageUrls(null);
  };

  const ImageContainer = styled(Box)({
    display: "flex",
    overflow: "auto",
    height: "100%",
    justifyContent: "center",
  });

  const StyledImage = styled("img")({
    maxWidth: "100px",
    margin: "10px",
    maxHeight: "100px",
  });

  useEffect(() => {
    if (scanedCode) {
      setFormValues((prevValues) => ({
        ...prevValues,
        code: scanedCode,
      }));
      setScanedCode("");
    }
  }, [scanedCode]);

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: "auto",
        borderRadius: 2,
      }}
    >
      <form style={{ overflow: "hidden" }} onSubmit={handleSubmit}>
        {imageUrls ? (
          <Stack direction="column" alignItems="center" spacing={2}>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="comment-images"
              type="file"
              multiple
              onChange={handleImageChange}
            />
            <label
              className={isDarkMode ? "stock-dark-mode" : "stock-light-mode"}
              style={{
                padding: 5,
                margin: 0,
              }}
              htmlFor="comment-images"
            >
              <IconButton
                component="span"
                className={
                  isDarkMode
                    ? "stock-primary-dark-mode"
                    : "stock-paper-light-mode"
                }
              >
                <Stack alignItems="center">
                  <Box>
                    <ImageContainer>
                      <StyledImage src={imageUrls} alt={`Adjunto`} />
                    </ImageContainer>
                    <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                      <IconButton
                        onClick={handleDeleteImage}
                        className={
                          isDarkMode
                            ? "stock-primary-dark-mode"
                            : "stock-paper-light-mode"
                        }
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Stack>
              </IconButton>
            </label>
          </Stack>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack direction="column" alignItems="center" spacing={2}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="comment-images"
                type="file"
                multiple
                onChange={handleImageChange}
              />
              <label
                className={isDarkMode ? "stock-dark-mode" : "stock-light-mode"}
                style={{
                  padding: 5,
                  margin: 0,
                }}
                htmlFor="comment-images"
              >
                <IconButton
                  component="span"
                  className={
                    isDarkMode
                      ? "stock-primary-dark-mode"
                      : "stock-paper-light-mode"
                  }
                >
                  <Stack alignItems="center">
                    <AddPhotoAlternateIcon sx={{ fontSize: "50px" }} />
                    <Typography>Añadir imagen</Typography>
                  </Stack>
                </IconButton>
              </label>
            </Stack>
          </Box>
        )}

        <Box
          className={isDarkMode ? "stock-dark-mode" : "stock-light-mode"}
          sx={{
            p: 2,
            marginTop: "10px",
          }}
        >
          <TextField
            fullWidth
            id="itemName"
            name="itemName"
            label="Nombre del producto"
            value={formValues.itemName}
            onChange={handleInputChange}
            margin="normal"
            required
            size="small"
            sx={{ marginTop: 0 }}
          />
          <TextField
            fullWidth
            id="code"
            name="code"
            label="Código"
            value={scanedCode || formValues.code}
            onChange={handleInputChange}
            margin="normal"
            required
            size="small"
            sx={{ marginTop: 0 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setScannerOpen(true)}>
                    <CropFreeIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Dialog
            open={scannerOpen}
            onClose={() => setScannerOpen(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Escanear Código de Barras</DialogTitle>
            <DialogContent>
              <BarcodeScanner onDetected={handleScan} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setScannerOpen(false)}>Cerrar</Button>
            </DialogActions>
          </Dialog>
          <TextField
            fullWidth
            id="openingStock"
            name="openingStock"
            label="Apertura de Stock"
            type="number"
            value={formValues.openingStock}
            onChange={handleInputChange}
            margin="normal"
            size="small"
            required
          />

          <TextField
            fullWidth
            id="reorderPoint"
            name="reorderPoint"
            label="Re stock"
            type="number"
            size="small"
            value={formValues.reorderPoint}
            onChange={handleInputChange}
            margin="normal"
            sx={{ marginTop: 0 }}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title="Establezca niveles minimo de re-stock para sus artículos y la aplicación le enviará una notificación cuando llegue el momento de actualizar su stock."
                  arrow
                >
                  <InputAdornment position="start">
                    <QuestionMarkIcon
                      sx={{
                        fontSize: "18px",
                        p: "2px",
                        borderRadius: "50%",
                        background: "black",
                        color: "white",
                      }}
                    />
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />

          <TextField
            select
            fullWidth
            id="category"
            name="category"
            label="Categoria"
            size="small"
            value={formValues.category}
            onChange={handleInputChange}
            required
            sx={{ marginTop: 0 }}
            margin="normal"
          >
            {categories.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            id="costPrice"
            name="costPrice"
            label="Precio de costo (ARS)"
            type="number"
            size="small"
            value={formValues.costPrice}
            onChange={handleInputChange}
            margin="normal"
            sx={{ marginTop: 0 }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              type="submit"
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ProductForm;
