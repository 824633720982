import {
  Breadcrumbs,
  Divider,
  IconButton,
  Box,
  styled,
  Typography,
  Alert,
  Stack,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import TopPanel from "./TopPanel";
import WorkshopPurchaseItemList from "./WorkshopPurchaseItemList";
import StateProgressBar from "./StateProgressBar";

import { WorkshopPurchase } from "../../entities/workshop-purchase.entity";
import { WorkshopPurchaseState } from "../../entities/workshop-purchase-state.entity";
import LinkTooltip from "../LinkTooltip";

import History from "./History";
import { Budget } from "../../entities/budget.entity";
import { useContext } from "react";
import { PurchaseContext } from "../Pages/Purchase/context/PurchaseContext";
import { useDarkMode } from "../ThemeContext";

const ContentLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "100%",
  margin: 10,

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "100%",
    gridTemplateRows: "auto auto auto auto",
    gridTemplateAreas: `"description"
                          "cart"
                          "purchase"
                          "History"`,
  },
}));

const modalContentStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  overflow: "hidden",
  display: "grid",
  gridTemplateRows: "auto auto 1fr",
};

interface ComponentProps {
  carSummary: string;
  purchase: WorkshopPurchase;
  handleClose: Function;
  onPurchaseChange: Function;
}

export default function ModalDetail({
  carSummary,
  purchase,
  handleClose,
  onPurchaseChange,
}: ComponentProps) {
  const isEditionDisabled = [
    WorkshopPurchaseState.CANCELLED_ID,
    WorkshopPurchaseState.EXPIRED_ID,
    WorkshopPurchaseState.REJECTED_ID,
  ].includes(purchase.workshopPurchaseState.id);

  const { purchases } = useContext(PurchaseContext);
  const { isDarkMode } = useDarkMode();
  const previousBudgetId = purchase.budget.previous_budget_id;

  const relatedPurchase = purchases
    ? purchases.find((p) => p.budget.id === previousBudgetId)
    : undefined;

  const linkBudget = `${
    import.meta.env.VITE_API_HOST
  }/budget-workshop/view?id=${purchase.budget.id}`;

  const styleDarkMode = () => {
    switch (true) {
      case purchase.is_additional && !isDarkMode:
        return "var(--purchase-additional-color)";
      case isDarkMode:
        return "#1C252E";
      default:
        return undefined;
    }
  };

  return (
    <Box
      sx={{
        ...modalContentStyle,
        backgroundColor: styleDarkMode(),
      }}
    >
      <Box display="grid" gridTemplateColumns="1fr auto">
        <Breadcrumbs aria-label="breadcrumb" sx={{ display: "grid" }}>
          <strong>{purchase.budget.company.name}</strong>
          <Box sx={{ display: "flex" }}>
            <strong style={{ marginRight: "5px" }}>
              {purchase.workshop.name}:{" "}
            </strong>
            <LinkTooltip
              linkCopy={linkBudget}
              text={purchase.budget.id}
              link={linkBudget}
              color={isDarkMode ? "#407AD6" : "blue"}
            />
          </Box>
          <Box>
            <strong>ID de compra: </strong>
            <LinkTooltip
              linkCopy={`${window.location.origin}/compras/${purchase.id}`}
              text={purchase.id}
              color={isDarkMode ? "#407AD6" : "blue"}
              link=""
            />
          </Box>
          {purchase.parent_id && (
            <Box>
              <strong>Presupuesto adicional - ID de compra principal: </strong>
              <LinkTooltip
                linkCopy={`${window.location.origin}/compras/${purchase.parent_id}`}
                text={purchase.parent_id}
                color={isDarkMode ? "#407AD6" : "blue"}
                link=""
              />
            </Box>
          )}
          {purchase.budget.previous_budget_id && relatedPurchase && (
            <Box>
              <strong>Recotización - ID de compra principal: </strong>
              <LinkTooltip
                linkCopy={`${window.location.origin}/compras/${relatedPurchase?.id}`}
                text={relatedPurchase?.id}
                color={isDarkMode ? "#407AD6" : "blue"}
                link=""
              />
            </Box>
          )}
        </Breadcrumbs>
        <IconButton onClick={() => handleClose()} sx={{ cursor: "pointer" }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <ContentLayout sx={{ overflowY: "auto" }}>
        <Box
          sx={{
            overflowY: "scroll",
            padding: "10px",
          }}
        >
          <Box>
            <Box>
              <Box sx={{ width: "100%" }}>
                <TopPanel
                  carSummary={carSummary}
                  car={purchase.budget.car}
                  budget={purchase.budget}
                  purchase={purchase}
                  onBudgetChange={(budget: Budget) => {
                    onPurchaseChange({ ...purchase, budget });
                  }}
                  onPurchaseChange={(purchase: WorkshopPurchase) =>
                    onPurchaseChange(purchase)
                  }
                  isEditionDisabled={isEditionDisabled}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ gridArea: "purchase" }}>
            <Paper>
              <Box sx={{ margin: "30px 0px 30px 0px", padding: "10px" }}>
                <Box
                  sx={{
                    background: isDarkMode
                      ? "rgb(64 122 214 / 8%)"
                      : "rgba(214, 20, 50, 0.08)",
                    border: isDarkMode
                      ? "solid 0.5px #fff"
                      : "solid 0.5px #fcdadc",
                    padding: "3px",
                    borderRadius: "5px",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  <strong>Descripción</strong>
                </Box>
                <Typography>{purchase.budget.description}</Typography>
              </Box>
            </Paper>
            <Box>
              <Stack direction="row" spacing={1}>
                <Alert sx={{ width: "100%" }} severity="info">
                  Los valores deben ser cargados sin IVA
                </Alert>
              </Stack>
              <Box sx={{ margin: "30px 0px 30px 0px" }}>
                <strong>Avance de la solicitud</strong>
                <StateProgressBar
                  states={purchase.workshopPurchaseItems.map(
                    (item) => item.workshopPurchaseItemState.name
                  )}
                />
              </Box>
            </Box>
            <Box>
              <WorkshopPurchaseItemList
                isEditionDisabled={isEditionDisabled}
                purchase={purchase}
                onPurchaseChange={onPurchaseChange}
              />
            </Box>
          </Box>
          <Box sx={{ gridArea: "History" }}>
            <strong>Actividad</strong>
            <History purchase={purchase} />
          </Box>
        </Box>
      </ContentLayout>
    </Box>
  );
}
