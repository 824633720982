import { Box, CircularProgress, Typography } from "@mui/material";

interface loadingLocation {
  component: string;
}

export default function Loading({ component }: loadingLocation) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "300px",
      }}
    >
      <CircularProgress size={24} sx={{ marginRight: 1 }} />
      <Typography>Cargando {component}...</Typography>
    </Box>
  );
}
