import { BudgetItem } from "./budget-item.entity";
import { Part } from "./part.entity";
import { WorkshopPurchaseItemState } from "./workshop-purchase-item-state.entity";

export interface LatestQuotePrice {
  workshopPurchaseItem: WorkshopPurchaseItem | null;
  date: Date | null;
}

export class WorkshopPurchaseItem {
  readonly id!: number;
  readonly brand?: string;
  readonly workshop_purchase_item_state_id!: number;
  readonly price!: number;
  readonly quantity!: number;
  readonly budgetItem!: BudgetItem;
  readonly workshopPurchaseItemState!: WorkshopPurchaseItemState;
  readonly description!: string;
  readonly has_sample!: boolean;
  readonly part?: Part;
  readonly part_id!: number;

  constructor(data: Partial<WorkshopPurchaseItem>) {
    Object.assign(this, data);
  }
}
